import React, { useState, useEffect } from 'react'
import { View, Text } from 'react-native'
import {
	getCountFromServer,
	collectionGroup,
	getFirestore,
	collection,
	query,
	where,
	FieldPath,
	WhereFilterOp
} from 'firebase/firestore'
import {
	app
} from './firebase'
import Grid from '@material-ui/core/Grid'
import {
	Analytics,
} from 'mcore';
import moment from 'moment'

const reportLink = "https://lookerstudio.google.com/embed/reporting/75e026a5-85fb-4eaf-89b5-3a201b575647/page/q5wSD"

export default function Dashboard() {

	const [showLoader, setShowLoader] = useState(true)
	const [statistics, setStatistics] = useState<Analytics.Statistics>()
	const [yesterday, setYesterday] = useState<Analytics.Statistics>()

	const [totalUsers, setTotalUsers] = useState(0)
	const [totalProjects, setTotalProjects] = useState(0)
	const [totalTrails, setTotalTrails] = useState(0)

	const [iphoneUsers, setIPhoneUsers] = useState(0)
	const [androidUsers, setAndroidUsers] = useState(0)
	const [ipadUsers, setIpadUsers] = useState(0)

	const [mapulatorPremium, setMapulatorPremium] = useState({
		total: 0,
		ios: 0,
		android: 0,
		todayTotal: 0,
		todayiOS: 0,
		todayAndroid: 0,
		yesterdayTotal: 0,
		yesterdayiOS: 0,
		yesterdayAndroid: 0,
		liteYearly: 0,
		liteMonthly: 0,
		proYearly: 0,
		proMonthly: 0,
		lifetime: 0,
	})

	const [trailsPremium, setTrailsPremium] = useState({
		total: 0,
		ios: 0,
		android: 0,
		todayTotal: 0,
		todayiOS: 0,
		todayAndroid: 0,
		yesterdayTotal: 0,
		yesterdayiOS: 0,
		yesterdayAndroid: 0,
		proYearly: 0,
		proMonthly: 0
	})

	const todayMidnight = getMidnightUTC()
	const yesterdayMidnight = getMidnightUTC(-1)

	function getMidnightUTC(offsetDays = 0) {
		const now = new Date();
		const midnightUTC = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate() + offsetDays));
		return midnightUTC;
	}

	useEffect(() => {
		initialLoad()
	}, [])

	async function initialLoad() {
		setShowLoader(true);
		try {

			const promises = [] as Promise<any>[]

			const dayId = Analytics.getDayIdFromPast(1);

			promises.push(new Promise<void>(async (resolve) => {
				const pastResponse = await Analytics.getHistory(dayId);
				setYesterday(pastResponse);
				resolve()
			}))

			promises.push(new Promise<void>(async (resolve) => {
				const response = await Analytics.getStatistics();
				setStatistics(response);
				resolve()
			}))


			promises.push(new Promise<void>(async (resolve) => {
				setTotalUsers(await getCount('users'))
				resolve()
			}))

			promises.push(new Promise<void>(async (resolve) => {
				setTotalProjects(await getCountFromGroup('projects'))
				resolve()
			}))

			promises.push(new Promise<void>(async (resolve) => {
				setTotalTrails(await getCountFromGroup('trails'))
				resolve()
			}))

			promises.push(new Promise<void>(async (resolve) => {
				setIPhoneUsers(await getCount('users', [
					{
						fieldPath: 'deviceInfo.OS.name',
						opStr: '==',
						value: 'iOS'
					}
				]))
				resolve()
			}))

			promises.push(new Promise<void>(async (resolve) => {
				setAndroidUsers(await getCount('users', [
					{
						fieldPath: 'deviceInfo.OS.name',
						opStr: '==',
						value: 'Android'
					}
				]))
				resolve()
			}))


			promises.push(new Promise<void>(async (resolve) => {

				setIpadUsers(await getCount('users', [
					{
						fieldPath: 'deviceInfo.OS.name',
						opStr: '==',
						value: 'iPadOS'
					}
				]))
				resolve()
			}))

			// Mapulator

			let total
			promises.push(new Promise<void>(async (resolve) => {
				total = await getCount('users', [
					{
						fieldPath: 'purchase.platform',
						opStr: 'in',
						value: ['web', 'android', 'ios']
					}
				])
				resolve()
			}))

			let ios
			promises.push(new Promise<void>(async (resolve) => {
				ios = await getCount('users', [
					{
						fieldPath: 'purchase.platform',
						opStr: '==',
						value: 'ios'
					}
				])
				resolve()
			}))

			let android
			promises.push(new Promise<void>(async (resolve) => {
				android = await getCount('users', [
					{
						fieldPath: 'purchase.platform',
						opStr: '==',
						value: 'android'
					}
				])
				resolve()
			}))

			let todayTotal
			promises.push(new Promise<void>(async (resolve) => {
				todayTotal = await getCount('users', [
					{
						fieldPath: 'purchase.transactionDate',
						opStr: '>',
						value: todayMidnight.getTime()
					}
				])
				resolve()
			}))

			let todayiOS
			promises.push(new Promise<void>(async (resolve) => {
				todayiOS = await getCount('users', [
					{
						fieldPath: 'purchase.platform',
						opStr: '==',
						value: 'ios'
					},
					{
						fieldPath: 'purchase.transactionDate',
						opStr: '>',
						value: todayMidnight.getTime()
					}
				])
				resolve()
			}))

			let todayAndroid
			promises.push(new Promise<void>(async (resolve) => {
				todayAndroid = await getCount('users', [
					{
						fieldPath: 'purchase.platform',
						opStr: '==',
						value: 'android'
					},
					{
						fieldPath: 'purchase.transactionDate',
						opStr: '>',
						value: todayMidnight.getTime()
					}
				])
				resolve()
			}))

			let yesterdayTotal
			promises.push(new Promise<void>(async (resolve) => {
				yesterdayTotal = await getCount('users', [
					{
						fieldPath: 'purchase.transactionDate',
						opStr: '>',
						value: yesterdayMidnight.getTime()
					},
					{
						fieldPath: 'purchase.transactionDate',
						opStr: '<',
						value: todayMidnight.getTime()
					}
				])
				resolve()
			}))

			let yesterdayiOS
			promises.push(new Promise<void>(async (resolve) => {
				yesterdayiOS = await getCount('users', [
					{
						fieldPath: 'purchase.platform',
						opStr: '==',
						value: 'ios'
					},
					{
						fieldPath: 'purchase.transactionDate',
						opStr: '>',
						value: yesterdayMidnight.getTime()
					},
					{
						fieldPath: 'purchase.transactionDate',
						opStr: '<',
						value: todayMidnight.getTime()
					}
				])
				resolve()
			}))

			let yesterdayAndroid
			promises.push(new Promise<void>(async (resolve) => {
				yesterdayAndroid = await getCount('users', [
					{
						fieldPath: 'purchase.platform',
						opStr: '==',
						value: 'android'
					},
					{
						fieldPath: 'purchase.transactionDate',
						opStr: '>',
						value: yesterdayMidnight.getTime()
					},
					{
						fieldPath: 'purchase.transactionDate',
						opStr: '<',
						value: todayMidnight.getTime()
					}
				])
				resolve()
			}))

			// Trails

			let totalTrails
			promises.push(new Promise<void>(async (resolve) => {
				totalTrails = await getCount('users', [
					{
						fieldPath: 'subscription.platform',
						opStr: 'in',
						value: ['web', 'android', 'ios']
					}
				])
				resolve()
			}))

			let iosTrails
			promises.push(new Promise<void>(async (resolve) => {
				iosTrails = await getCount('users', [
					{
						fieldPath: 'subscription.platform',
						opStr: '==',
						value: 'ios'
					}
				])
				resolve()
			}))

			let androidTrails
			promises.push(new Promise<void>(async (resolve) => {
				androidTrails = await getCount('users', [
					{
						fieldPath: 'subscription.platform',
						opStr: '==',
						value: 'android'
					}
				])
				resolve()
			}))

			let todayTotalTrails
			promises.push(new Promise<void>(async (resolve) => {
				todayTotalTrails = await getCount('users', [
					{
						fieldPath: 'subscription.transactionDate',
						opStr: '>',
						value: todayMidnight.getTime()
					}
				])
				resolve()
			}))

			let todayiOSTrails
			promises.push(new Promise<void>(async (resolve) => {
				todayiOSTrails = await getCount('users', [
					{
						fieldPath: 'subscription.platform',
						opStr: '==',
						value: 'ios'
					},
					{
						fieldPath: 'subscription.transactionDate',
						opStr: '>',
						value: todayMidnight.getTime()
					}
				])
				resolve()
			}))

			let todayAndroidTrails
			promises.push(new Promise<void>(async (resolve) => {
				todayAndroidTrails = await getCount('users', [
					{
						fieldPath: 'subscription.platform',
						opStr: '==',
						value: 'android'
					},
					{
						fieldPath: 'subscription.transactionDate',
						opStr: '>',
						value: todayMidnight.getTime()
					}
				])
				resolve()
			}))

			let yesterdayTotalTrails
			promises.push(new Promise<void>(async (resolve) => {
				yesterdayTotalTrails = await getCount('users', [
					{
						fieldPath: 'subscription.transactionDate',
						opStr: '>',
						value: yesterdayMidnight.getTime()
					},
					{
						fieldPath: 'subscription.transactionDate',
						opStr: '<',
						value: todayMidnight.getTime()
					}
				])
				resolve()
			}))

			let yesterdayiOSTrails
			promises.push(new Promise<void>(async (resolve) => {
				yesterdayiOSTrails = await getCount('users', [
					{
						fieldPath: 'subscription.platform',
						opStr: '==',
						value: 'ios'
					},
					{
						fieldPath: 'subscription.transactionDate',
						opStr: '>',
						value: yesterdayMidnight.getTime()
					},
					{
						fieldPath: 'subscription.transactionDate',
						opStr: '<',
						value: todayMidnight.getTime()
					}
				])
				resolve()
			}))

			let yesterdayAndroidTrails
			promises.push(new Promise<void>(async (resolve) => {
				
				yesterdayAndroidTrails = await getCount('users', [
					{
						fieldPath: 'subscription.platform',
						opStr: '==',
						value: 'android'
					},
					{
						fieldPath: 'subscription.transactionDate',
						opStr: '>',
						value: yesterdayMidnight.getTime()
					},
					{
						fieldPath: 'subscription.transactionDate',
						opStr: '<',
						value: todayMidnight.getTime()
					}
				])
				resolve()
			}))

			// Mapulator

			let liteYearly
			let liteMonthly
			let proYearly
			let proMonthly
			let lifetime

			promises.push(new Promise<void>(async (resolve) => {
				liteYearly = await getCount('users', [
					{
						fieldPath: 'purchase.productId',
						opStr: '==',
						value: 'com.logisian.mapulator.lite.yearly'
					}
				])
				resolve()
			}))
			promises.push(new Promise<void>(async (resolve) => {
				liteMonthly = await getCount('users', [
					{
						fieldPath: 'purchase.productId',
						opStr: '==',
						value: 'com.logisian.mapulator.lite.monthly'
					}
				])
				resolve()
			}))
			promises.push(new Promise<void>(async (resolve) => {
				proYearly = await getCount('users', [
					{
						fieldPath: 'purchase.productId',
						opStr: '==',
						value: 'com.logisian.mapulator.feature.yearly'
					}
				])
				resolve()
			}))
			promises.push(new Promise<void>(async (resolve) => {
				proMonthly = await getCount('users', [
					{
						fieldPath: 'purchase.productId',
						opStr: '==',
						value: 'com.logisian.mapulator.feature.monthly'
					}
				])
				resolve()
			}))
			promises.push(new Promise<void>(async (resolve) => {
				lifetime = await getCount('users', [
					{
						fieldPath: 'purchase.productId',
						opStr: '==',
						value: 'com.logisian.mapulator.feature'
					}
				])
				resolve()
			}))

			// Trails
			let proYearlyTrails
			let proMonthlyTrails

			promises.push(new Promise<void>(async (resolve) => {
				proYearlyTrails = await getCount('users', [
					{
						fieldPath: 'subscription.productId',
						opStr: '==',
						value: 'com.logisian.trails.premium.yearly'
					}
				])
				resolve()
			}))
			promises.push(new Promise<void>(async (resolve) => {
				proMonthlyTrails = await getCount('users', [
					{
						fieldPath: 'subscription.productId',
						opStr: '==',
						value: 'com.logisian.trails.premium.monthly'
					}
				])
				resolve()
			}))


			await Promise.all(promises)

			setMapulatorPremium({
				android,
				ios,
				todayAndroid,
				todayiOS,
				todayTotal,
				total,
				yesterdayAndroid,
				yesterdayiOS,
				yesterdayTotal,
				lifetime,
				liteMonthly,
				liteYearly,
				proMonthly,
				proYearly
			})

			setTrailsPremium({
				proMonthly: proMonthlyTrails,
				proYearly: proYearlyTrails,
				android: androidTrails,
				ios: iosTrails,
				todayAndroid: todayAndroidTrails,
				todayiOS: todayiOSTrails,
				todayTotal: todayTotalTrails,
				total: totalTrails,
				yesterdayAndroid: yesterdayAndroidTrails,
				yesterdayiOS: yesterdayiOSTrails,
				yesterdayTotal: yesterdayTotalTrails
			})

			console.log('Premium', mapulatorPremium)

		} catch (error) {
			console.log('Error Fetching Analytics', error)
		}
		setShowLoader(false);
	}

	async function onPressRefresh() {
		initialLoad()
	}

	// if (showLoader) return <Progress />

	return (
		<div style={{
			backgroundColor: 'rgb(32,33,36)',
			padding: '4em',
			minHeight: '95vh'
		}}>
			<div>
				<button onClick={onPressRefresh}>Refresh</button>
			</div>
			<br />
			<Grid>
				<div style={{
					display: 'flex',
					flexWrap: 'wrap'
				}}>										
					<Card
						title="All Projects"
						description="Since v6.0"
						isLoading={showLoader}
						value={Number(statistics?.totalProjects)}
						oldValue={yesterday?.totalProjects}/>
					&nbsp;&nbsp;&nbsp;
					<Card
						title="Open Issues"
						isLoading={showLoader}
						value={Number(statistics?.totalOpenIssues)}
						oldValue={yesterday?.totalOpenIssues}/>
					&nbsp;&nbsp;&nbsp;
					<Card
						isLoading={showLoader}
						value={totalProjects}
						title='Total Projects' />
					&nbsp;&nbsp;&nbsp;					
					<Card
						title="Total Trails"
						description=""
						isLoading={showLoader}
						value={totalTrails}/>
				</div>
				<br />
				<hr />
				<br />
				<label style={{ color: 'white' }}>All Users</label>
				<br /><br />
				<div style={{
					display: 'flex',
					flexWrap: 'wrap'
				}}>
					<Card
						isLoading={showLoader}
						value={totalUsers}
						title='Total Users' />
					&nbsp;&nbsp;&nbsp;
					<Card
						isLoading={showLoader}
						value={iphoneUsers}
						title='iOS Users' />
					&nbsp;&nbsp;&nbsp;
					<Card
						isLoading={showLoader}
						value={androidUsers}
						title='Android Users' />
					&nbsp;&nbsp;&nbsp;
					<Card
						isLoading={showLoader}
						value={ipadUsers}
						title='iPad Users' />
				</div>
				<br />
				<hr />
				<br />
				<label style={{ color: 'white' }}>Mapulator Premium</label>
				<br /><br />
				<div style={{
					display: 'flex',
					flexWrap: 'wrap'
				}}>
					<Card
						isLoading={showLoader}
						value={mapulatorPremium.total}
						title='Total Premium Users' />
					&nbsp;&nbsp;&nbsp;
					<Card
						isLoading={showLoader}
						value={mapulatorPremium.ios}
						title='iOS Users' />
					&nbsp;&nbsp;&nbsp;
					<Card
						isLoading={showLoader}
						value={mapulatorPremium.android}
						title='Android Users' />
				</div>
				<br /><br />
				<div style={{
					display: 'flex',
					flexWrap: 'wrap'
				}}>
					<Card
						isLoading={showLoader}
						value={mapulatorPremium.liteMonthly}
						title='Lite - Monthly' />
					&nbsp;&nbsp;&nbsp;
					<Card
						isLoading={showLoader}
						value={mapulatorPremium.liteYearly}
						title='Lite - Yearly' />
					&nbsp;&nbsp;&nbsp;
					<Card
						isLoading={showLoader}
						value={mapulatorPremium.proMonthly}
						title='Pro - Monthly' />
					&nbsp;&nbsp;&nbsp;
					<Card
						isLoading={showLoader}
						value={mapulatorPremium.proYearly}
						title='Pro - Yearly' />
					&nbsp;&nbsp;&nbsp;
					<Card
						isLoading={showLoader}
						value={mapulatorPremium.lifetime}
						title='Lifetime' />
				</div>
				<br />
				<hr />
				<br />
				<label style={{ color: 'white' }}>Trails Premium</label>
				<br /><br />
				<div style={{
					display: 'flex',
					flexWrap: 'wrap'
				}}>
					<Card
						isLoading={showLoader}
						value={trailsPremium.total}
						title='Total Premium' />
					&nbsp;&nbsp;&nbsp;
					<Card
						isLoading={showLoader}
						value={trailsPremium.ios}
						title='iOS Users' />
					&nbsp;&nbsp;&nbsp;
					<Card
						isLoading={showLoader}
						value={trailsPremium.android}
						title='Android Users' />
				</div>
				<br /><br />
				<div style={{
					display: 'flex',
					flexWrap: 'wrap'
				}}>
					<Card
						isLoading={showLoader}
						value={trailsPremium.proMonthly}
						title='Pro - Monthly' />
					&nbsp;&nbsp;&nbsp;
					<Card
						isLoading={showLoader}
						value={trailsPremium.proYearly}
						title='Pro - Yearly' />
				</div>
				<br />
				<hr />
				<br />
				<label style={{ color: 'white' }}>Mapulator - Today vs Yesterday</label>
				<div style={{
					color: 'white',
					marginTop: '0.5em'
				}}><b>{moment(todayMidnight).utc().format('MMM DD YYYY')}</b> vs <b>{moment(yesterdayMidnight).utc().format('MMM DD YYYY')}</b> in UTC</div>
				<br /><br />
				<div style={{
					display: 'flex',
					flexWrap: 'wrap'
				}}>
					<Card
						isLoading={showLoader}
						value={(mapulatorPremium.todayTotal)}
						oldValue={mapulatorPremium.yesterdayTotal}
						title='Total Premium Users' />
					&nbsp;&nbsp;&nbsp;
					<Card
						isLoading={showLoader}
						value={mapulatorPremium.todayiOS}
						oldValue={mapulatorPremium.yesterdayiOS}
						title='iOS Users' />
					&nbsp;&nbsp;&nbsp;
					<Card
						isLoading={showLoader}
						value={mapulatorPremium.todayAndroid}
						oldValue={mapulatorPremium.yesterdayAndroid}
						title='Android Users' />
				</div>
				<br />
				<hr />
				<br />
				<label style={{ color: 'white' }}>Trails - Today vs Yesterday</label>
				<div style={{
					color: 'white',
					marginTop: '0.5em'
				}}><b>{moment(todayMidnight).utc().format('MMM DD YYYY')}</b> vs <b>{moment(yesterdayMidnight).utc().format('MMM DD YYYY')}</b> in UTC</div>
				<br /><br />
				<div style={{
					display: 'flex',
					flexWrap: 'wrap'
				}}>
					<Card
						isLoading={showLoader}
						value={(trailsPremium.todayTotal)}
						oldValue={trailsPremium.yesterdayTotal}
						title='Total Premium Users' />
					&nbsp;&nbsp;&nbsp;
					<Card
						isLoading={showLoader}
						value={trailsPremium.todayiOS}
						oldValue={trailsPremium.yesterdayiOS}
						title='iOS Users' />
					&nbsp;&nbsp;&nbsp;
					<Card
						isLoading={showLoader}
						value={trailsPremium.todayAndroid}
						oldValue={trailsPremium.yesterdayAndroid}
						title='Android Users' />
				</div>
			</Grid>
		</div>
	);

	async function getCount(path: string, filters?: {
		fieldPath: string | FieldPath,
		opStr: WhereFilterOp,
		value: unknown
	}[]) {
		const db = getFirestore(app)
		const collectionRef = collection(db, path);
		let ref = query(collectionRef)
		filters?.forEach(({
			fieldPath,
			opStr,
			value
		}) => {
			ref = query(ref, where(fieldPath, opStr, value))
		})
		const response = await getCountFromServer(ref)
		return response.data().count
	}

	async function getCountFromGroup(path: string, filters?: {
		fieldPath: string | FieldPath,
		opStr: WhereFilterOp,
		value: unknown
	}[]) {
		const db = getFirestore(app)
		const collectionRef = collectionGroup(db, path);
		let ref = query(collectionRef)
		filters?.forEach(({
			fieldPath,
			opStr,
			value
		}) => {
			ref = query(ref, where(fieldPath, opStr, value))
		})
		const response = await getCountFromServer(collectionRef)
		return response.data().count
	}
}

function Card(props: {
	title: string,
	description?: string,
	value: number,
	isLoading: boolean,
	oldValue?: number
}) {
	const { title, value, description, isLoading, oldValue } = props
	const change = value - (oldValue ?? 0)
	return (
		<View style={{
			width: 210,
			height: 180,
			justifyContent: 'center',
			alignItems: 'center',
			borderWidth: 1,
			borderColor: '#dddddd',
			borderRadius: 10,
		}}>
			<Text style={{
				textAlign: 'center',
				fontWeight: '500',
				color: 'white'
			}}>{title}</Text>
			<View style={{
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
				marginVertical: 20
			}}>
				<Text style={{
					fontSize: 35,
					textAlign: 'center',
					color: 'white'
				}}>{isLoading ? '--' : value ?? 0}</Text>
				{oldValue && <Text style={{
					fontSize: 22,
					textAlign: 'center',
					color: 'white'
				}}>{oldValue}</Text>}
				{oldValue && <Text style={{
					fontSize: 15,
					textAlign: 'center',
					color: change === 0 ? 'gray' : (change < 0 ? 'red' : '#19c246'),
					marginTop: 1
				}}>{isLoading ? '--' : Math.abs(change)}</Text>}
			</View>
			{description && <Text style={{
				fontSize: 13,
				textAlign: 'center',
				color: 'gray'
			}}>{isLoading ? '--' : description}</Text>}
		</View>
	)
}
